/* General Hero Section */
.hero-section {
    padding: 50px 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
    animation: fadeIn 1s ease-in-out;
    flex-wrap: wrap; /* Added to ensure items wrap on smaller screens */
}

/* Text section */
.text-section {
    width: 55%;
    padding: 0 32px;
    animation: slideInLeft 1s ease-in-out;
    flex: 1; /* Added to ensure responsiveness */
}
.text-headline {
    margin-bottom: 16px;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: .7px;
}
.text-title {
    width: 100%;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-size: 42px;
    font-weight: bold;
    line-height: 1.3;
}
.text-description {
    width: 100%;
    margin: 24px 0;
    color: rgb(77, 75, 75);
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    letter-spacing: .7px;
    line-height: 1.8rem;
}
.text-appointment-btn {
    padding: 14px 24px;
    color: white;
    border: 1px solid transparent;
    border-radius: 30px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.text-appointment-btn:hover {
    color: #1A8EFD;
    background-color: transparent;
    border: 1px solid #1A8EFD;
}

/* Stats Section */
.text-stats {
    margin-top: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    flex-wrap: wrap; /* Added to ensure items wrap on smaller screens */
}
.text-stats-container {
    text-align: center;
    animation: fadeInUp 1s ease-in-out;
}
.text-stats-container > p {
    color: rgb(77, 75, 75);
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    letter-spacing: .7px;
}
.text-stats-container p:first-child {
    margin-bottom: 8px;
    color: #1A8EFD;
    font-family: 'Rubik', sans-serif;
    font-size: 36px;
    font-weight: bold;
}

/* Image section */
.hero-image-section {
    width: 40%;
    max-width: 100%;
    text-align: center;
    animation: slideInRight 1s ease-in-out;
}
.hero-image1 {
    width: 100%;
    height: auto;
    border-radius: 12px;
}

/* Scroll Btn */
.scroll-up {
    width: 50px;
    height: 50px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #1A8EFD;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
    animation: fadeIn 0.5s ease-in-out;
}
.show-scroll {
  display: flex;
}

/* Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideInLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slideInRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Responsive */
@media screen and (max-width: 900px) {
    .hero-image-section {
        display: none;
    }
    .text-section {
        width: 100%;
        padding: 0;
        text-align: center;
    }
    .text-title, .text-description {
        width: 100%;
    }
    .text-stats {
        justify-content: center; /* Center the stats on smaller screens */
    }
}
@media screen and (max-width: 600px) {
    .text-headline {
        font-size: 20px;
    }
    .text-title {
        font-size: 30px;
    }
    .text-description {
        font-size: 16px;
    }
    .text-appointment-btn {
        font-size: 16px;
    }
    .text-stats {
        gap: 20px;
    }
    .text-stats-container > p {
        font-size: 16px;
    }
    .text-stats-container p:first-child {
        font-size: 24px;
    }
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}
