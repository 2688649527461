.navbar-section {
    padding: 0 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    background-color: white;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-logo-container {
    display: flex;
    align-items: center;
    gap: 8px;
}

.navbar-logo {
    width: 40px;
    height: auto;
}

.navbar-title a {
    color: #1A8EFD;
    letter-spacing: .6px;
    text-decoration: none;
}

.navbar-title {
    font-family: 'Poppins', sans-serif;
}

.navbar-items {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 32px;
    font-family: 'Rubik', sans-serif;
}

.navbar-links {
    text-decoration: none;
    color: black;
    font-size: 18px;
    letter-spacing: .8px;
}

.navbar-links:hover {
    color: #0cc2ea;
}

.navbar-buttons {
    display: flex;
    align-items: center;
    gap: 12px;
}

.navbar-btn {
    padding: 12px 18px;
    color: white;
    border: 1px solid transparent;
    border-radius: 28px;
    outline: transparent;
    background-color: #1A8EFD;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    letter-spacing: .8px;
    cursor: pointer;
    transition: all .4s ease;
}

.navbar-btn:hover {
    color: #1A8EFD;
    background-color: white;
    border: 1px solid #1A8EFD;
}

.navbar-create-btn {
    padding: 12px 18px;
    background-color: #54de54;
    color: white;
    border-radius: 28px;
    text-decoration: none;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: bold;
    letter-spacing: .8px;
    transition: all .4s ease;
}

.navbar-create-btn:hover {
    background-color: white;
    color: #54de54;
    border: 1px solid #54de54;
}

/* Hamburger Icon */
.mobile-nav {
    display: none;
}

.hamb-icon {
    width: 26px;
    height: 26px;
    cursor: pointer;
}

.hamb-icon:hover {
    color: #0cc2ea;
}

/* Mobile Navbar */
.mobile-navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    background-color: white;
    z-index: 20;
    justify-content: center;
    align-items: center;
    transition: left .5s ease-in-out;
}

.mobile-navbar-close {
    position: absolute;
    top: 28px;
    right: 28px;
}

.mobile-navbar-close .hamb-icon:hover {
    color: rgb(255, 22, 22);
}

.open-nav {
    left: 0;
}

.mobile-navbar-links {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    gap: 24px;
    text-align: center;
}

.mobile-navbar-links li a {
    text-decoration: none;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    letter-spacing: .8px;
    transition: color .3s ease-in-out;
}

.mobile-navbar-links li a:hover {
    color: #0cc2ea;
}

/* Responsive */
@media screen and (max-width: 900px) {
    .navbar-items {
        display: none;
    }
    .navbar-buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    .navbar-btn, .navbar-create-btn {
        width: 100%;
        text-align: center;
    }
    .mobile-nav {
        display: block;
    }
    @media (max-width: 768px) {
        .navbar-create-btn {
          display: none;
        }
    }
}
