.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 20px;
    height: 100vh;
    background-color: #f4f4f9;
    color: #333;
  }
  
  .not-found-title {
    font-size: 3rem;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .not-found-message {
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
  
  .not-found-details {
    font-size: 1rem;
    color: #666;
    margin: 10px 0;
  }
  
  .highlight {
    color: #d9534f;
    font-weight: bold;
  }
  
  .not-found-suggestion {
    font-size: 1.2rem;
    color: #007bff;
    margin: 20px 0;
  }
  
  .home-link {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .home-link:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .not-found-title {
      font-size: 2.5rem;
    }
  
    .not-found-message,
    .not-found-details {
      font-size: 1rem;
    }
  
    .not-found-suggestion {
      font-size: 1.1rem;
    }
  
    .home-link {
      padding: 8px 16px;
    }
  }
  